import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import useSnackbarHook from '../hooks/useSnackbarHook';

import Api from '../../services/api';
import useFormValidationRules from '../hooks/useFormValidationRules';

export default function Subscribe() {
    const [checked, setChecked] = useState(false);
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const { validationRules } = useFormValidationRules();
    const { Alert, alertState, setAlertState, handleError } = useSnackbarHook();

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const addContact = async (userInput) => {
        const [response, error] = await Api.addContact(userInput);
        if (error) {
            handleError(error);
            return;
        }
        if (response.data.body) {
            setAlertState({ severity: 'success', message: t('forms.contact_added'), showAlert: true });
            reset();
            setChecked(false);
        } else {
            setAlertState({ severity: 'error', message: t('forms.errors.contact_exists'), showAlert: true });
        }
    }

    return (
        <>
            <section className='subscribe_box'>
                <p className='subscribe-question'>{t("home.subscribe_question")}</p>
                <h2 className='section-title'>{t("home.subscribe_prompt")}</h2>
                <form className='subscription-form' onSubmit={handleSubmit(addContact)}>
                    <div className='subscription-wrapper'>
                        <input className='subscription-input' type='email'
                            required
                            placeholder={t("forms.user_email")}
                            {...register('email', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.email } })}
                        />
                    </div>
                    {errors.email && <sub className='helper-text'>{errors.email.message}</sub>}
                    <div className='policy-agreement-wrapper'>
                        <Checkbox id='privacy-policy' className='policy-agreement'
                            checked={checked}
                            onChange={handleCheck}
                            sx={{
                                color: '#162032',
                                '&.Mui-checked': {
                                    color: '#162032',
                                },
                            }} required />
                        <label htmlFor='privacy-policy' className='policy-agreement-label'>
                            <span>{t("legal_agreements.email_subscription")}</span>
                        </label>
                    </div>
                    <div className='subscription-disclaimer'>
                        <span>{t("home.subscribe_disclaimer.p1")}</span>
                        <a href='/politicas/privacidad' target='_blank' className='text-link'>{t("legal_agreements.privacy_policy")}</a>
                        <span>{t("home.subscribe_disclaimer.p2")}</span>
                        <a href='/politicas/cookies' target='_blank' className='text-link'>{t("legal_agreements.cookies_policy")}</a>
                    </div>
                    <button type='submit' className='subscription-submit'>
                        {t('actions.subscribe')}
                    </button>
                </form>
            </section>
            <Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
                <Alert severity={alertState.severity} sx={{ width: '100%', fontSize: '2rem' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </>
    )
}
